.employee-form {
  .employee-card {
    .ant-card-actions {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }
}
