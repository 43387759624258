@import "src/assets/styles/variables";

.main-menu {
  min-width: 320px;
}

.main-menu-list {
  display: flex;
  flex-wrap: wrap;
  width: 470px;
}

.menu-btn {
  margin: 0 25px;
  cursor: pointer;
  background-color: $white;
  border: none;

  .icon {
    width: 20px;
    height: 20px;
  }
}
