@import "src/assets/styles/variables";

.main-menu-item {
  display: flex;
  flex-wrap: wrap;
  width: 33.333%;
  padding: 10px 5px;
  cursor: pointer;
  justify-content: center;
  background-color: transparent;
  border: none;

  .icon {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
    transition: 0.3s;
    object-fit: contain;
  }

  &__title {
    width: 100%;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.3s;
  }

  &:hover {
    .icon {
      color: $mainBlue;
    }

    .main-menu-item__title {
      color: $mainBlue;
    }
  }
}
