.goal-window {
  &__citizen {
    margin-bottom: 20px;
  }

  &__actions {
    .common-btn {
      margin-left: 10px;
    }
  }
}

.goal-form {
  .common-input, .textarea, .select-menu, .text-editor {
    margin-bottom: 20px;
  }
}
