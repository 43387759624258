.card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -24px -24px;
  padding: 12px 15px;
  border-top: 1px solid #d9d9d9;

  &__left {
    .common-btn {
      margin-right: 10px;

      &:first-child {
        margin-right: 0;
      }
    }
  }

  &__right {
    .common-btn {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
