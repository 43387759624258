@import "../../../../assets/styles/variables";

.textarea {
  position: relative;

  textarea {
    resize: none;
  }

  &--readonly {
    textarea {
      pointer-events: none;
    }
  }

  &--error {
    .ant-input, .ant-input:focus {
      border-color: $errorRed;
    }
  }
}