@import "../../../assets/styles/variables";

.autocomplete {
  position: relative;

  .ant-select {
    width: 100%;
  }

  &--error {
    .ant-input, .ant-input:focus {
      border-color: $errorRed;
    }
  }

  &__loading-icon {
    position: absolute;
    display: flex;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
  }
}