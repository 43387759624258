@import "src/assets/styles/variables";

.health-area {
  width: 25%;
  padding: 10px;

  &__content {
    padding: 5px 10px;
    text-align: left;
    border: 1px solid $border;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  &__head-actions {
    display: flex;

    .common-btn {
      margin-left: 5px;
    }
  }

  &__name {
    display: block;
    background-color: transparent;
    border: none;
    text-align: left;
    font-size: 16px;
    padding: 0;
    font-weight: bold;
    cursor: pointer;
  }

  .hal-item {
    border-bottom: 1px solid $black;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
}
