.hal-item {
  padding-bottom: 10px;
  margin-bottom: 10px;

  &__head {
    display: flex;

    .common-btn {
      flex-shrink: 0;
      margin-left: 10px;
    }
  }

  &__title {
    width: 400px;
    background-color: transparent;
    border: none;
    padding-right: 10px;
    text-align: left;
    cursor: pointer;
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__info {
    padding-top: 10px;
    font-size: 12px;
  }
}
