.documents-window {
  &__actions {
    display: flex;
    justify-content: space-between;
  }

  &__save-btn {
    margin-right: 10px;
  }
}
