@import 'src/assets/styles/variables';

.calendar-sidebar {
  width: 0;
  height: 100%;
  padding-right: 6px;
  overflow: hidden;
  transition: 0.3s;
  transition-timing-function: ease-out;
  background-color: $white;
  border-right: 1px solid transparent;

  &__content {
    width: 280px;
    margin-bottom: 5px;
  }

  &__departments {
    padding: 20px 10px 0;
    margin-bottom: 20px;

    .ant-upload {
      width: 100%;
    }
  }

  &__fav {
    margin-bottom: 10px;

    .common-btn {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 10px;
    }
  }

  .checkbox {
    margin-bottom: 10px;
  }

  * {
    user-select: none;
  }

  .ant-collapse-header {
    white-space: nowrap;
    padding: 5px 16px 5px 40px !important;
  }

  &--active {
    width: 280px;
    border-right-color: $border;
  }

  &__options {
    max-height: calc(100vh - 320px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ant-checkbox-wrapper {
    white-space: nowrap;

    span {
      white-space: nowrap;
    }
  }
}
