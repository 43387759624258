@import "src/assets/styles/variables";

.health-section {
  &__latest {
    background-color: $white;
    padding-left: 50px;
    padding-top: 12px;

    &-items {
      display: flex;
      align-items: center;
    }
  }

  &__sections {
    padding-top: 16px;
  }

  &__log-btn {
    margin: 15px 0;
  }
}
