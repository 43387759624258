@import "src/assets/styles/variables";

.medicine-section {
  padding: 15px;
  background-color: $white;

  &__actions {
    margin-bottom: 20px;

    .common-btn {
      margin-right: 10px;
    }
  }
}
