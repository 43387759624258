@import "../../../assets/styles/variables";

.ov-details-field {
  user-select: none;

  &__header {
    span {
      font-weight: 700;
    }

    &--important {
      font-weight: 700;
      color: $errorRed;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;

    button {
      margin-left: 10px;
    }
  }

  .ant-collapse-extra {
    margin-left: 10px !important;
  }

  .ant-collapse {
    border: none !important;
    background-color: transparent !important;
  }

  .ant-collapse-header {
    padding: 0 !important;
    background-color: transparent !important;
    margin-bottom: 14px;

    .ov-details-field__header {
      margin-bottom: 0;
    }
  }

  .ant-collapse-content, .ant-collapse-item {
    border: none !important;
  }

  .ant-collapse-arrow {
    left: -16px !important;
  }

  .ant-collapse-content-box {
    padding-top: 0 !important;
  }
}
