@import "src/assets/styles/variables";

.medicine-table {
  margin-bottom: 40px;

  &__title {
    font-size: 20px !important;
    font-weight: bold !important;
    text-align: left !important;
  }

  &__edit-btn {
    border: 1px solid $border !important;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    white-space: nowrap;
  }

  &--expired {
    button {
      color: $errorRed;
    }
  }
}
