.hasc-window {
  .ant-modal-body {
    display: none;
  }

  &__row {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;

    p {
      max-width: 350px;
    }
  }

  &__footer {
    text-align: left;

    .common-btn {
      margin-bottom: 8px;

      &:nth-child(3) {
        margin-left: 0;
      }
    }
  }
}
