.ord-adm {
  padding-bottom: 80px;

  &__week {
    max-width: 200px;
  }

  &__month {
    display: flex;

    .common-input {
      margin-right: 20px;
    }
  }

  &__actions {
    .common-btn {
      margin-right: 15px;
    }
  }

  &__tabs-wrap {
    padding-top: 20px;
  }
}
