@import "src/assets/styles/variables";

.header {
  display: flex;
  height: 80px;
  padding-left: 20px;
  align-items: center;
  background-color: $white;

  &__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .page-title {
      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__logo {
    cursor: pointer;
  }

  &__actions {
    display: flex;
    align-items: center;

    .citizen-search {
      margin-right: 60px;
    }
  }

  &__calendar-btn {
    position: absolute;
    left: 15px;
    top: 25px;
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 1px 5px 15px -5px $black;
  }

  &__toolbar-box {
    padding-top: 10px;
    padding-left: 20px;
  }

  &__doc-btn {
    .icon {
      width: 18px;
      height: 18px;
    }
  }
}
