@import "src/assets/styles/variables";

.pageHeader {
  border: 2px solid $border;
}

.content {
  flex-grow: 1;
  min-width: 0; // So the Typography noWrap works
  background-color: #EDF1F6;
  padding: 1.5rem;
  color: #5a5c69 !important;
  overflow-x: hidden;

  &-home {
    overflow: hidden;
    margin: 0 !important;
  }
}
