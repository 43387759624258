.force {
  // padding: 20px;

  .common-card.ant-card {
    .ant-card-head-title {
      text-align: center;
    }
  }

  &__info {
    display: flex;
    width: 100%;
    // max-width: 1100px;
    margin: 0 auto 30px;
  }

  &__days {
    margin-right: 60px;
  }
}
