.hae-window {
  &__form {
    display: block !important;
    width: 100%;

    .text-editor {
      margin-bottom: 20px;
    }
  }

  &__calendar-title {
    margin-bottom: 10px;
  }
}