@import "src/assets/styles/variables";

.health-areas {
  &__items {
    display: flex;
    flex-wrap: wrap;
    padding: 6px;
  }

  .ant-collapse-item {
    &.currently {
      .ant-collapse-header {
        background-color: $errorRed;
      }

      .health-area__content {
        border: 2px solid $errorRed;
      }
    }

    &.none {
      .ant-collapse-header {
        background-color: $success;
      }

      .health-area__content {
        border: 2px solid $success;
      }
    }

    &.notconsided {
      .ant-collapse-header {
        background-color: $grey;
      }

      .health-area__content {
        border: 2px solid $grey;
      }
    }

    &.potentially {
      .ant-collapse-header {
        background-color: $danger;
      }

      .health-area__content {
        border: 2px solid $danger;
      }
    }
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-header {
    font-weight: bold;
  }

  .area {
    display: block;
    background-color: transparent;
    border: none;
    text-align: left;

    &__items {
      padding-left: 15px;
    }
  }
}
