@import "src/assets/styles/variables";

.measurements-table {
  margin: 0 auto;

  tbody tr {
    background-color: $white;
  }

  &__actions {
    display: flex;

    .common-btn {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
