.cai-form {
  padding-bottom: 30px;

  .common-input, .text-editor, .select-menu {
    margin-bottom: 20px;
  }

  &__full-day {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .checkbox {
      margin-right: 30px;
    }

    .date-picker {
      margin-bottom: 0;

      .ant-picker {
        width: 250px;
      }
    }
  }

  &__private {
    margin-bottom: 20px;
  }

  &__btns-left {
    .common-btn {
      margin-right: 10px;
    }
  }

  &__btns-right {
    display: flex;

    .common-btn {
      margin-left: 10px;
    }
  }

  &__tt-duration {
    margin-bottom: 15px;
  }
}

.cai-participants {
  display: flex;
}
