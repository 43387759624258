.ordination-window {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
  }

  &__col {
    width: 100%;
    padding: 0 20px;
    margin: 0 0 30px;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
  }

  &__actions {
    .common-btn {
      margin-left: 10px;
    }
  }
}
