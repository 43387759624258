.mes-window {
  &__department {
    margin-bottom: 20px;
    font-weight: bold;
  }

  &__type {
    margin-bottom: 20px;
  }

  &__type-selector {
    .rc-virtual-list-holder {
      max-height: inherit !important;
      overflow: visible !important;
    }

    .rc-virtual-list-scrollbar {
      display: none;
    }
  }
}