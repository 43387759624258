.od-table {
  .ant-table-wrapper {
    position: relative;
    z-index: 20;
    margin-bottom: 15px;
  }

  .ant-table-thead > tr > th {
    font-size: 12px;
    padding: 5px;
  }

  .common-btn {
    font-size: 12px;
    padding: 5px;
  }

  .ant-table-tbody > tr > td {
    font-size: 12px;
    padding: 5px;
  }

  .time-picker {
    margin-bottom: 0;
  }
}
