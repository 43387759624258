/* Grid */

.column {
  flex-basis: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.column {
  flex: 1;
}
._25 {
  flex: 2.5;
}
._12 {
  flex: 1.2;
}
._5 {
  flex: 5;
}
.bold {
  font-weight: bold;
}
/* Style */

// body {
//   font-family: 'Lato', sans-serif;
//   font-size: 1.3em;
//   color: #ccc;
//   //   background: #000;
//   margin-bottom: 70px;
// }

.column {
  padding: 5px;
  border: 1px solid #666;
  // margin: 5px 0;
  //   background: #343436;
}

main {
  //   max-width: 1200px;
  margin: 0 auto;
}

h1,
h2 {
  text-align: center;
}
.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.rtag:hover {
  opacity: 0.2;
}
.controls {
  margin-right: 5px;
}
