@import 'src/assets/styles/variables';

.goals {
  // flex-grow: 1;
  // padding: 20px;

  &__card {
    flex-grow: 1;
    margin-bottom: 15px !important;
    background-color: transparent !important;

    .ant-card-body {
      padding: 0 !important;
    }
  }

  &__head {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 10px;
    background-color: $white;

    .select-menu {
      width: 200px;
      margin-right: 20px;
    }

    &-actions {
      padding: 20px 0 0;
    }
  }
}
