.ot-title {
  font-size: 18px;

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 6px;
  }

  button {
    margin-left: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
