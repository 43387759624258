/* @import url('https://fonts.googleapis.com/css?family=Roboto'); */
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
body {
  margin: 0;
  padding: 0;
  /* font-family: 'Roboto', sans-serif; */
  font-family: Montserrat, 'sans-serif';
}
.block {
  display: block;
  width: 100%;
  border: none;
  background-color: #4caf50;
  margin: 0px 16px !important;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}
html,
body {
  height: 100%;
}
#root {
  flex-grow: 1;
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  height: 100%;
}
.appBar {
  z-index: 2;
}
main {
  padding: 0px !important;
  width: 100%;
}
.flexContainer {
  display: flex;
  overflow: auto;
}
.fullwidth {
  width: 100%;
}
.flexColumn {
  flex-direction: column;
}
.flexRight {
  justify-content: flex-end;
  display: flex;
}
.marginTop60 {
  margin-top: 60px;
}

.standardMarginLeftRight {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.standardSpaceRightTextbox {
  margin-right: 0.5rem !important;
}
.marginBottom {
  margin-bottom: 4px !important;
}
.ant-table-tbody > tr > td{
  /* padding-right: 3px !important; */
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.ant-table-thead > tr > th{
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}