.mc-fields {

  .hc-field {

    .common-input {
      width: 100%;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
