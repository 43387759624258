@import "../../../../assets/styles/variables";

.common-input {
  position: relative;

  &--error {
    .ant-input, .ant-input:focus {
      border-color: $errorRed;
    }
  }

  &--caution {
    .ant-input {
      font-weight: bold;
      background-color: $errorRed;
      color: $white;
    }
  }

  &--readonly {
    pointer-events: none;
  }
}
