@import "../../../../assets/styles/variables";

.hc-field {
  display: flex;
  align-items: center;

  &__title {
    margin-right: 10px;
  }

  &__value {
    min-width: 200px;
    height: 34px;
    padding: 5px 10px;
    border: 1px solid $border;

    &--empty {
      color: $black !important;
      background-color: $white !important;
    }

    &--cave {
      color: $white !important;
      background-color: $errorRed !important;
    }
  }
}
