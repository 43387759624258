.documents {
	// padding: 20px;

	.common-card.ant-card {
		width: 100%;
		margin: 0 auto 30px;

		.ant-card-head-title {
			text-align: center;
		}
	}
}
