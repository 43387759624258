.medicine {
  // margin: 0 auto;

  .ant-tabs-nav {
    display: none !important;
  }
}

.medicine-sidebar-btn {
  width: 100%;
  margin-bottom: 10px;
}
