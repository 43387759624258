@import 'src/assets/styles/variables';

.sidebar {
  display: flex;
  width: 1px;
  height: 100%;
  flex-shrink: 0;
  background-color: $white;
  border-right: 2px solid $border;
  overflow: hidden;
  transition: 0.3s;
  transition-timing-function: ease-out;

  &__content {
    width: 250px;
    padding: 10px;
  }

  &__menu {
    padding: 10px 0 10px 10px;

    a {
      display: block;
      width: 25px;
      height: 25px;
      margin-bottom: 20px;
      color: $black;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        &.icon-active {
          display: none;
        }
      }

      .icon {
        width: 100%;
        height: 100%;
      }

      &.active {
        color: $mainBlue;

        img {
          display: none;

          &.icon-active {
            display: block;
          }
        }
      }
    }
  }

  &--active {
    width: 285px;
  }
}
