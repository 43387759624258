.cn-list {
  flex: 1;

  &__actions {
    display: flex;

    .common-btn {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

}
