.overview-page {
  width: 100%;
  background-color: #fff;
  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .ov-tree {
      display: flex;
    }
  }
}
