.employee-form {
  &__actions {
    .common-btn {
      margin-right: 10px;
    }
  }

  .employee-card {
    .ant-card-actions {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }

  &__account {
    padding-left: 50px;
  }
}
