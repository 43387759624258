.card {
	width: 350px;
	height: 250px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}
.button {
	width: 100%;
}
