.df-item {
  display: flex;
  margin-top: 10px;
  align-items: flex-start;

  &__left {
    width: 50%;
    padding-top: 25px;
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    width: 50%;

    .select-menu {
      width: 50%;
      padding-right: 10px;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__actions {
    margin-left: 15px;
    height: 18px;

    button {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }

    .icon {
      width: 100%;
      height: 100%;
    }
  }

  &__name-wrap {
    width: calc(100% - 80px);

    .common-input {
      margin-bottom: 0 !important;
    }
  }

  &__name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
