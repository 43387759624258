@keyframes saveAppear {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.cai-drawer {
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--right {
      .common-btn {
        margin-left: 10px;
      }
    }

    &--left {
      .common-btn {
        margin-right: 10px;
      }
    }
  }
}

.cai-save-btn-wrapper {
  animation: 1s saveAppear;
}