@import "../../../../assets/styles/variables";

.date-picker {
  position: relative;
  margin-bottom: 20px;

  &--error {
    .ant-input, .ant-input:focus, .ant-picker, .ant-picker-focused {
      border-color: $errorRed;
    }
  }

  &--title-left {
    display: flex;
    align-items: center;

    .date-picker__title {
      margin-right: 10px;
    }
  }

  .ant-calendar-picker, .ant-picker {
    width: 100%;
  }
}

.ant-calendar-date-input-wrap input {
  pointer-events: none;
}
