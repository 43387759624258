.ml-item {
  display: flex;
  background-color: transparent;
  border: none;
  flex-wrap: wrap;
  margin-right: 30px;
  align-items: center;

  &__title {
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
  }

  &__value {
    margin: 0 15px;
  }

  &__add-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &__date {
    width: 100%;
  }
}
