.footer-action {
  padding-top: 12px;
  position: fixed;
  left: 0;
  bottom: 0px;
  right: 0;
  height: 57px;
  background-color: white;
  color: black;
  text-align: right;
  z-index: 200;
  border-top: 1px solid #d9d9d9;
  padding-right: 10px;
  display: flex;
  justify-content: center;
}
