@import "src/assets/styles/variables";

.items-search {
  position: relative;

  .ant-select {
    width: 100%;
  }

  &--error {
    .ant-select-selector, .ant-select-selector:focus {
      border-color: $errorRed !important;
    }
  }
}