@import "../../../../assets/styles/variables";

.select-menu {
  position: relative;

  .ant-select {
    width: 100%;
  }

  &--error {
    .ant-select-selector, .ant-select-selector:focus {
      border-color: $errorRed !important;
    }
  }

  .ant-tag.with-error {
    color: $errorRed;
  }
}

.ant-select-dropdown {
  width: auto !important;
}

.ant-select-item-option-content {
  padding: 0 2px;
}