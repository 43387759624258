.ps-label {
  display: flex;
  align-items: center;
  margin-right: 20px;

  &__name {
    margin-right: 6px;
  }

  .icon {
    width: 14px;
    height: 14px;
  }
}
