.citizen-form {
  .flexContainer {
    width: 100%;
  }

  .common-card {
    // margin: 0 auto !important;
  //  width: 100% !important;
  //  max-width: 1100px !important;
  }
}
