@import "src/assets/styles/variables";

.text-editor {
  width: 100%;

  &__wrap {
    position: relative;

    &--error {
      .text-editor__toolbar {
        border-color: $errorRed;
      }

      .text-editor__area {
        border-color: $errorRed;
        border-top-color: transparent;
      }
    }

    &--disabled {
      cursor: not-allowed;

      .text-editor__area, .text-editor__toolbar {
        background-color: #f5f5f5;
        pointer-events: none;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  &__toolbar {
    display: flex;
    padding: 5px;
    margin-bottom: 0;
    border: 1px solid $border;

    .rdw-inline-wrapper, .rdw-list-wrapper {
      margin-bottom: 0;
    }

    .rdw-option-wrapper {
      width: 30px;
      height: 30px;
      padding: 7px;
      border-color: $border;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &.rdw-option-active {
        background-color: $grey;
      }

      &:hover {
        box-shadow: none;
      }
    }
  }

  &__area {
    height: 200px;
    padding: 0 5px;
    border: 1px solid $border;
    border-top-color: transparent;
    overflow: auto;

    div, ul, ol {
      margin: 0;
    }

    .public-DraftEditor-content > div {
      padding: 10px 0;
    }
  }
}
