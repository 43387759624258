.home-card {
  position: relative;
  display: flex;
  flex-grow: 1;
  border: none !important;

  .ant-card-body {
    display: flex;
    padding: 0px;
    flex-grow: 1;
  }

  &__left-edge {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 12px;
  }
}

.home-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  //display: flex;
  //width: 100%;
  //flex-direction: column;
}

.menuItem {
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 3px;
  display: block;
}

.menuHeader {
  margin-left: 10px;
  margin-right: 10px;
  display: block;
  font-size: 15px;
}
.ant-select-selector,
.ant-btn {
  // border-radius: 4px !important;
}
