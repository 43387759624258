@import "src/assets/styles/variables";

.goal-section {
  padding: 10px 0;
  background-color: $white;

  &:last-child {
    padding-bottom: 0;
  }

  &__content {
    display: flex;
    padding-top: 20px;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  &__sub-items {
    padding-top: 20px;
  }

  &__sub-wrap {
    position: relative;
    margin-bottom: 45px;
    margin-left: 50px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 100%;
      top: 50%;
      height: 1px;
      width: 50px;
      background-color: $border;
    }
  }
}
