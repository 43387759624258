.gs-head {
  margin-bottom: 30px;

  &__filters {
    display: flex;
    margin: 0 -20px 20px;

    .select-menu {
      width: 50%;
      padding: 0 20px;
    }
  }
}
